import * as types from 'constants/ActionTypes';
import initialState from 'config/initialState';


export default function nodeRevisionsReducer(state = initialState.nodeRevisions, action) {
  function processRevisions(revisions) {
    
  }

  switch (action.type) {

    default:
      return state;
  }
}
